let awsRegion = 'ap-southeast-2';

export default {
 api_urls: {
   reporting: 'https://vcw4zcljtj.execute-api.ap-southeast-2.amazonaws.com/api',
   geojson: 'https://getedq4ei6.execute-api.ap-southeast-2.amazonaws.com/api',
   overlays: 'https://t3pkn5dir2.execute-api.ap-southeast-2.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 2277,
 crm_base_url: 'https://ccm.staging.newcrest.forwoodsafety.com',
 forwood_id_url: 'https://id.staging.newcrest.forwoodsafety.com?redirect_uri=https://geoeditor.staging.newcrest.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.staging.newcrest.forwoodsafety.com',
 Auth: {
   userPoolId: 'ap-southeast-2_2m3LORGQ4',
   userPoolWebClientId: '1jk640q61bvh4tsad33db9vc1d',
   cookieStorage: {
     domain: '.staging.newcrest.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'True',
 env: 'staging'
};
